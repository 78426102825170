import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import FeedViewComponent from 'components/FeedViewComponent';

interface LocationType {
  indexNumber: number;
}
const FeedViewPage: FC = () => {
  const location = useLocation();
  const state = location.state as LocationType;
  const { indexNumber } = state;

  return <FeedViewComponent indexNumber={indexNumber} />;
};

export default FeedViewPage;
