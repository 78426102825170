import React from 'react';
import InvalidPageComponent from "../components/InvalidPageComponent";

const InvalidPage = () => {
  return (
    <>
      <InvalidPageComponent/>
    </>
  );
};

export default InvalidPage;
