import React from 'react';

import HeaderNavigation from 'shared/HeaderNavigation';

import { Container, ContentBox } from './styles';

import kifAttentionMe from 'assets/images/kifAttentionMe.png';

const FeedViewComponent = () => {
  return (
    <Container>
      <HeaderNavigation />
      <ContentBox>
        <img src={kifAttentionMe} alt="kifAttentionMe" width="100%" height="100%" />
      </ContentBox>
    </Container>
  );
};
export default FeedViewComponent;
