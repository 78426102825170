import styled from '@emotion/styled';

export const ProfileSection = styled.section`
  text-align: center;
  margin-bottom: 30px;
  &.sphh {
    padding: 100px 0;
    margin-bottom: 0;
  }
  &.dongwon {
    padding-top: 80px;
    padding-bottom: 50px;
    margin-bottom: 0;
  }
  &.dongwon.bigName {
    padding-bottom: 10px;
  }
`;

export const ProfileImage = styled.span`
  width: 82px;
  height: 82px;
  border-radius: 50%;

  margin: 0 auto;
  margin-bottom: 10px;
  //background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  & img {
    width: 100%;
    height: 100%;
  }
  &.circleFullImage img {
    width: 100%;
    height: 100%;
  }
`;

export const SphhLogoImage = styled.img`
  width: 255px;
  height: 33px;
  display: block;
  margin: 0 auto;
  margin-bottom: 10px;
`;

export const BannerImage: any = styled.div`
  width: 100%;
  height: 185px;
  background-image: url(${(props: any) => props.image});
  background-size: cover;
`;

export const ProfileName = styled.span`
  display: block;
  font-size: 12px;
  color: #fff;
  font-weight: 700;
  &.dongwon {
    font-family: 'Montserrat', sans-serif;
  }
  &.bigName {
    font-size: 70px;
  }
  &.midName {
    font-size: 24px;
  }
  @media screen and (max-width: 365px) {
    &.bigName {
      font-size: 50px;
    }
  }
`;
