import styled from '@emotion/styled';

export const SocialListContainer = styled.section`
  position: relative;
  z-index: 1;
  padding: 10px 0 38px 0;
  //background-color: #fff;
  //border-top: 1px solid rgba(0, 0, 0, 0.05);
`;

export const SocialItemBox = styled.div`
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 12px 10px;
  &.sphh {
    border-radius: 50%;
    background: #ffffff;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.18);
  }
`;

export const SocialListBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -webkit-box-align: center;
  //align-items: center;
  //justify-content: space-evenly;

  justify-content: center;
  flex-direction: row;
`;

export const SocialItem = styled.span`
  &.sphh {
    width: 25px;
    height: 25px;
  }
  &.dongwon {
    //width: 30px;
    //height: 30px;
  }
`;
