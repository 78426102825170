import React from 'react';
// import { useLocation } from 'react-router-dom';
import IntroComponent from "../components/IntroComponent";

const IntroPage = () => {
  return (
    <>
      <IntroComponent />
    </>
  );
};

export default IntroPage;
