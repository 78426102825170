import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

const RedirectPage = () => {
  const { pk } = useParams();
  let navigate = useNavigate();
  useEffect(() => {
    axios
      .get(`/api/qr/re/${pk}`)
      .then((response: any) => {
        if (response.data.isExternal) {
          window.location.replace(response.data.current_link);
        } else {
          navigate(`/${response.data.dynamic_name}`);
        }
      })
      .catch((error: any) => {
        console.log('실패 : ', error.response);
      });
  }, [pk, navigate]);

  return (
    <div className="loadingLayout">
      <div className="loading-container">
        <div className="loading"></div>
        <div id="loading-text">loading</div>
      </div>
    </div>
  );
};

export default RedirectPage;
