import React, { FC } from 'react';

interface Props {
  color?: string;
}

const HomePageIcon: FC<Props> = ({ color }) => {
  return (
    <svg width="25px" height="25px" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21 9.25195L11.888 1.52395C11.376 1.09195 10.624 1.09195 10.112 1.52395L1 9.25195"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M3.11133 7.29993V14.7879C3.11133 16.8279 4.76733 18.4759 6.79933 18.4759H15.1913C17.2313 18.4759 18.8793 16.8199 18.8793 14.7879V7.29993"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M8.02539 17.9881V14.2121C8.02539 12.5641 9.36139 11.2361 11.0014 11.2361C12.6494 11.2361 13.9774 12.5721 13.9774 14.2121V17.9881"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default HomePageIcon;
