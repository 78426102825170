import styled from '@emotion/styled';
interface ItemWrapperProps {
  hasUrl?: boolean;
  hasPath?: boolean;
  hasYoutubeView?: boolean;
}

export const ItemWrapper = styled.div<ItemWrapperProps>`
  position: relative;
  margin-top: ${(props) => (!props.hasUrl && !props.hasPath && !props.hasYoutubeView ? '24px' : '0')};
`;

export const ComingSoonLabel = styled.div`
  position: absolute;
  top: -20px; // LinkContainer 위에 위치
  left: 10px;
  //background-color: #ff6b6b; // 또는 원하는 색상
  color: white;
  padding: 2px 8px;
  border-radius: 4px;
  font-size: 15px;
  font-style: italic;
  z-index: 1;
`;

export const LinkContainer = styled.button`
  display: block;
  width: 100%;
  height: ${(props) => (props.disabled ? '40px' : '60px')};
  border: none;
  background-color: #fff;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.24);
  //border-bottom: 1px solid #333;
  //border-radius: 7px;
  margin-bottom: 20px;
  position: relative;
  padding: 0 20px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  //opacity: ${(props) => (props.disabled ? 0.5 : 1)};

  &.isThumbnail {
    padding: 0 66px;
    overflow: hidden;
  }

  &.dongwon:not(.isThumbnail) {
    background-color: transparent;
    box-shadow: none;
    padding: 0 16px;
  }

  &.dongwon.isThumbnail {
    background-color: transparent;
    box-shadow: none;
    padding: 0;
    padding-left: 66px;
  }
  // 이미지없으면
  // padding 0 16px;
`;

export const LinkThumbnail = styled.img`
  width: 60px;
  height: 60px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  & {
    border-radius: 50%;
  }
`;

export const LinkName = styled.span<{ fontName: any; disabled: any }>`
  display: block;
  font-size: 18px;
  font-weight: 700;
  //color: #fff;
  text-align: left;
  padding-left: 20px;
  color: #000;
  font-family: ${(props: any) => props.fontName}, sans-serif;

  @media (max-width: 320px) {
    & {
      font-size: 16px;
    }
  }
  &.dongwon {
    //font-family: 'CookieRun-Regular', sans-serif;
    background: rgba(255, 255, 255, 0.16);
    border: 1.5px solid #ffffff;
    border-radius: 27px;
    font-size: 15px;

    height: 100%;
    box-sizing: border-box;
    line-height: ${(props) => (props.disabled ? '40px' : '60px')};
    color: #fff;
    text-align: center;

    padding-left: 0;
  }
`;
