import React, { FC } from 'react';

interface Props {
  color?: string;
}

const FacebookIcon: FC<Props> = ({ color }) => {
  return (
    <svg width="24" height="30" viewBox="0 0 14 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.692 1.41162L11.6915 1.41156C11.1327 1.33985 10.2646 1.25 9.40828 1.25C7.82671 1.25 6.43632 1.73213 5.44044 2.72895C4.4438 3.72653 3.92878 5.15292 3.92878 6.87168V8.88017H2.20505C1.4014 8.88017 0.75 9.53157 0.75 10.3352V12.5449C0.75 13.3485 1.4014 14 2.20505 14H3.92917V21.2949C3.92917 22.0986 4.58057 22.75 5.38422 22.75H7.88725C8.6909 22.75 9.3423 22.0986 9.3423 21.2949V14H10.9047C11.6228 14 12.234 13.4761 12.3429 12.7655L12.4003 12.3907L12.4 12.3904L12.6815 10.5558C12.6815 10.5557 12.6815 10.5557 12.6815 10.5556C12.8169 9.67394 12.1344 8.88017 11.2434 8.88017H9.34152V7.28143C9.34152 6.89538 9.43736 6.60622 9.59581 6.423C9.73669 6.2601 10.0172 6.07604 10.6321 6.07604H11.5059C12.3095 6.07604 12.9609 5.42464 12.9609 4.62099V2.85465C12.9609 2.12274 12.4175 1.50513 11.692 1.41162Z"
        stroke={color}
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default FacebookIcon;
