import styled from '@emotion/styled';

export const Container = styled.div`
  width: 100%;
  text-align: center;
`;

export const ContentBox = styled.div``;
export const LinkSection = styled.section``;

export const CloseButton = styled.button`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid #fff;
  text-align: center;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 3px auto 0 auto;

  & img {
    width: 25px;
    height: 25px;
  }
`;
