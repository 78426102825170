import React, { FC, useCallback } from 'react';
import {
  ConfirmBackground,
  ContentBox,
  ContentComment,
  ContentNoti,
  ContentTitle,
  LinkButton,
  UserLogo,
} from './styles';

import confirmIcon from 'assets/icons/confirm.svg';
import incelldermLogo from 'assets/images/incelldermLogo.png';

interface Props {
  indonesia?: boolean;
  incellderm?: boolean;
}

const ConfirmPageComponent: FC<Props> = ({ indonesia, incellderm }) => {
  const handleIncellderm = useCallback((link: string) => {
    window.location.href = `${link}`;
  }, []);
  return (
    <ConfirmBackground>
      <ContentBox>
        {incellderm && <UserLogo src={incelldermLogo} alt="incelldermLogo" />}

        <img src={confirmIcon} alt="confirmIcon" className="confirmLogo" />

        {incellderm ? (
          <>
            <ContentTitle>정품입니다</ContentTitle>
            <ContentComment>이 제품은 인셀덤에서 생산한 정품입니다.</ContentComment>
            <ContentNoti>URCODE is genuine</ContentNoti>
            <br />
            <ContentNoti>8809639176030</ContentNoti>
            <ContentNoti>RTB0045219</ContentNoti>
            <br />
            <LinkButton onClick={() => handleIncellderm('https://incellderm.rimankorea.com/')}>
              홈페이지 바로가기
            </LinkButton>
          </>
        ) : indonesia ? (
          <>
            <ContentTitle>It is genuine.</ContentTitle>
            <ContentComment>The product recognized with</ContentComment>
            <ContentNoti>URCODE is genuine</ContentNoti>
          </>
        ) : (
          <>
            <ContentTitle>정품입니다</ContentTitle>
            <ContentComment>이 상품권은 신세계에서 발행한 정품 상품권입니다.</ContentComment>
            <ContentNoti>URCODE is genuine</ContentNoti>
          </>
        )}
      </ContentBox>
    </ConfirmBackground>
  );
};

export default ConfirmPageComponent;
