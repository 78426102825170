import styled from '@emotion/styled';

export const InvalidBackground = styled.section`
  width: 100%;
  height: 100vh;
  background: linear-gradient(180deg, #FFA3C1 0%, #DC3B5C 100%);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ContentBox = styled.div`
  //width: 215px;
  //height: 255px;
  text-align: center;
  color: #ffffff;

  & img.confirmLogo {
    display: block;
    width: 112px;
    height: 112px;
    margin: 0 auto;
  }
`;

export const ContentTitle = styled.span`
  display: block;
  font-weight: 700;
  font-size: 28px;
  margin-top: 38px;
  margin-bottom: 30px;
`;

export const ContentComment = styled.span`
  font-weight: 400;
  font-size: 17px;
  display: block;
  margin-bottom: 5px;
`;
export const ContentNoti = styled.span`
  font-weight: 400;
  font-size: 17px;
  display: block;
`;

export const LinkButton = styled.span`
  font-weight: 400;
  font-size: 17px;
  display: block;
`;
export const UserLogo = styled.img`
  width: 200px;
  height: 130px;
  display: block;
  margin: 0 auto;
  margin-bottom: 30px;
`;
