import React, { FC } from 'react';
import {
  ContentBox,
  ContentComment,
  ContentNoti,
  ContentTitle, InvalidBackground,
} from './styles';

import invalidIcon from 'assets/icons/invalidIcon.svg';

interface Props {
  indonesia?: boolean;
  incellderm?: boolean;
}

const InvalidPageComponent: FC<Props> = ({ indonesia, incellderm }) => {
  return (
    <InvalidBackground>
      <ContentBox>
        <img src={invalidIcon} alt="confirmIcon" className="confirmLogo" />
          <>
            <ContentTitle>다시 시도해 주세요</ContentTitle>
            <ContentComment>UR코드가 없는 이미지거나, 잘못된 UR code 입니다.</ContentComment>
            <ContentNoti>This is an invalid UR code or </ContentNoti>
            <ContentNoti>an image with no UR code.</ContentNoti>
          </>
      </ContentBox>
    </InvalidBackground>
  );
};

export default InvalidPageComponent;
