import React from 'react';
import { Container } from './styles';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';

import { sphhLinkList } from 'utils/dummy';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

import 'styles/swiper.css';

const FeedViewComponent = ({ indexNumber }: any) => {
  return (
    <Container>
      <Swiper navigation={true} modules={[Navigation]} className="mySwiper" initialSlide={indexNumber}>
        {sphhLinkList.feedList.map((feed, index) => (
          <SwiperSlide key={index}>
            <img src={feed.image} alt={feed.name} />
          </SwiperSlide>
        ))}
      </Swiper>
    </Container>
  );
};
export default FeedViewComponent;
