import React, { FC } from 'react';

interface Props {
  color?: string;
}

const TiktokIcon: FC<Props> = ({ color }) => {
  return (
    <svg viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.26471 8.59115C5.34596 8.45033 3.31715 9.49096 2.17825 11.713C0.469894 15.0461 1.88179 20.5 7.5811 20.5C13.2804 20.5 13.4453 14.9928 13.4453 14.4158C13.4453 14.0311 13.4453 11.8805 13.4453 7.96395C14.6643 8.73608 15.6921 9.20085 16.5286 9.35827C17.3651 9.51574 17.897 9.58553 18.124 9.56764V6.35789C17.3503 6.26461 16.6811 6.08681 16.1166 5.82452C15.2699 5.43102 13.5908 4.33904 13.5908 2.73855C13.592 2.74638 13.592 2.33353 13.5908 1.5H10.0627C10.0523 9.33929 10.0523 13.6445 10.0627 14.4158C10.0785 15.5727 9.18102 17.191 7.36138 17.191C5.54169 17.191 4.64421 15.574 4.64421 14.531C4.64421 13.8925 4.86349 12.967 5.77009 12.2817C6.30772 11.8753 7.05393 11.713 8.26471 11.713C8.26471 11.3385 8.26471 10.2979 8.26471 8.59115Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TiktokIcon;
