import React, { FC, useEffect, useState } from 'react';

import ReactPlayer from 'react-player/youtube';

import {
  // CloseButton,
  Container,
  ContentBox,
  LinkSection,
} from './styles';
import LinkItem from 'shared/LinkItem';

import KoreanFlag from 'assets/images/KoreanFlag.png';
import kifAttentionMeLogo from 'assets/images/kifAttentionMeLogo.png';
import { useParams } from 'react-router-dom';

interface Props {
  data?: any;
}

const MainLinkTreeComponent: FC<Props> = ({ data }) => {
  const [innerWidth, setInnerWidth] = useState(0);
  const params = useParams();

  useEffect(() => {
    setInnerWidth(window.innerWidth - 40);
  }, []);

  return (
    <Container>
      <ContentBox>
        <LinkSection>
          {data.theme.themeName === 'sphh' && (
            <LinkItem name="데니 태극기" path="/sphh/feed" thumbnail={KoreanFlag} nickName={data.profile.nickname} />
          )}
          {Array.isArray(data.list)
            ? data.list.map((item: any) => (
                <LinkItem
                  key={item.id}
                  nickName={data.profile.nickname}
                  name={item.name}
                  url={item.url}
                  thumbnail={item.thumbnail}
                  themeName={data.theme.themeName}
                  fontName={data.theme.font}
                />
              ))
            : params.urCode && data.list[params.urCode]
            ? data.list[params.urCode].map((item: any) => (
                <LinkItem
                  key={item.id}
                  nickName={data.profile.nickname}
                  name={item.name}
                  url={item.url}
                  label={item.label}
                  thumbnail={item.thumbnail}
                  themeName={data.theme.themeName}
                  fontName={data.theme.font}
                />
              ))
            : null}
          {data.theme.themeName === 'kiff' && (
            <LinkItem
              name="어텐션미 콘테스트"
              path="/2022kif/view"
              nickName={data.profile.nickname}
              thumbnail={kifAttentionMeLogo}
              themeName={data.theme.themeName}
            />
          )}
          {data.theme.themeName === 'kiff' && data.theme.themeName === 'mhsc' && (
            <>
              <ReactPlayer
                url="https://www.youtube.com/embed/1ZJ4TGTLDrA?amp&autoplay=1&mute=1"
                width="100%"
                height={`${innerWidth}px`}
                playing={true}
                muted={true}
                controls={true}
              />
            </>
          )}
        </LinkSection>
      </ContentBox>
    </Container>
  );
};

export default MainLinkTreeComponent;
