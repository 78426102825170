import React, { FC } from 'react';

interface Props {
  color?: string;
}

const EmailIcon: FC<Props> = ({ color }) => {
  return (
    <svg viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1.25" y="1.25" width="21.3" height="17.5" rx="4.25" stroke={color} strokeWidth="1.5" />
      <path
        d="M3.99903 6L11.2469 10.2084C11.5574 10.3887 11.9407 10.3887 12.2512 10.2084L19.499 6"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default EmailIcon;
