import React, {FC} from 'react';
import {BannerImage, ProfileImage, ProfileName, ProfileSection, SphhLogoImage} from './styles';
import profileImage from 'assets/images/winter.jpeg';
import {useLocation, useParams} from 'react-router-dom';
import quryString from 'query-string';

interface Props {
    profile?: any;
    themeName?: string;
    urCodePath: string[];
}

const Profile: FC<Props> = ({profile, themeName, urCodePath}) => {
    const location = useLocation();
    const params = useParams();
    const pathname = location.pathname;
    const {ur} = quryString.parse(location.search);
    // console.log(location.pathname === '/incellderm_official');
    return (
        <ProfileSection
            className={`${themeName === 'sphh' ? 'sphh' : ''}${
                themeName === 'dongwon' || themeName === 'kiff' || themeName === 'mhsc' ? 'dongwon' : ''
            } ${
                pathname === '/100001' ||
                pathname === '/100002' ||
                pathname === '/000001' ||
                pathname === '/000002' ||
                pathname === '/200001' ||
                pathname === '/200002' ||
                pathname === '/110001' ||
                pathname === '/110002' ||
                pathname === '/120001' ||
                pathname === '/120002' ||
                pathname === '/300001' ||
                pathname === '/300002' ||
                urCodePath.indexOf(pathname.split('/')[1]) !== -1
                    ? `bigName`
                    : pathname.split('/')[1] === 'happyhabit' && ur !== undefined ? `midName` : ''
            }`}
        >
            {profile.bannerUrl ? (
                <BannerImage image={profile.bannerUrl}/>
            ) : themeName === 'sphh' ? (
                <SphhLogoImage src={profile.sphhLogo} alt="sphhLogo" width="100%"/>
            ) : themeName === 'dongwon' || themeName === 'kiff' || themeName === 'mhsc' ? (
                <>
                    <ProfileImage>
                        <img src={profile.dongwonLogo} alt="profileImage"/>
                    </ProfileImage>
                    <ProfileName
                        className={
                            pathname === '/100001' ||
                            pathname === '/100002' ||
                            pathname === '/000001' ||
                            pathname === '/000002' ||
                            pathname === '/200001' ||
                            pathname === '/200002' ||
                            pathname === '/110001' ||
                            pathname === '/110002' ||
                            pathname === '/120001' ||
                            pathname === '/120002' ||
                            pathname === '/300001' ||
                            pathname === '/300002' ||
                            (urCodePath.indexOf(pathname.split('/')[1]) !== -1 &&
                                pathname.split('/').length === 3 &&
                                pathname.split('/')[2] !== '')
                                ? `bigName`
                                : pathname === '/logen001' || pathname === '/logen002' || pathname === '/logen003' || (pathname.split('/')[1] === 'happyhabit' && ur !== undefined)
                                    ? `midName`
                                    : `dongwon`
                        }
                    >

                        {(pathname.split('/')[1] === 'happyhabit' && ur !== undefined)
                            ? `# ${ur}`
                            :
                            urCodePath.indexOf(pathname.split('/')[1]) !== -1 &&
                            pathname.split('/').length === 3 &&
                            pathname.split('/')[2] !== ''
                                ? `@${params.urCode}`
                                : `@${profile.nickname}`}

                    </ProfileName>
                </>
            ) : (
                <>
                    <ProfileImage>
                        <img src={profileImage} alt="profileImage"/>
                    </ProfileImage>
                    <ProfileName>@Winter</ProfileName>
                </>
            )}
            {location.pathname === '/incellderm_official' && <div style={{fontSize: '20px'}}>테스트버전입니다.</div>}
        </ProfileSection>
    );
};

export default Profile;
