import styled from '@emotion/styled';

export const LayoutContainer: any = styled.div`
  width: 100%;
  height: 100vh;

  background-image: url(${(props: any) => props.bgImg});
  background-size: cover;
  background-position: center center;
  //overflow: hidden;
  &.kiff {
    //height: 100%;
    overflow: auto;
  }
`;

export const SideWhiteSpace = styled.div`
  //height: calc(100% - 360px);
  padding: 20px;
  box-sizing: border-box;
  &.dongwon {
    height: calc(100% - 310px);
  }
  &.sphh {
    height: calc(100% - 360px);
  }
  &.bigName {
    height: calc(100% - 351px);
  }
`;

export const URlogo = styled.img`
  width: 103px;
  height: 25px;
  position: relative;
  z-index: 1;
  padding: 10px 0 38px 0;
  //background-color: #fff;
  //border-top: 1px solid rgba(0, 0, 0, 0.05);

  display: block;
  margin: 0 auto;
`;
