import styled from '@emotion/styled';

export const Container = styled.div`
  width: 100%;
  height: 56px;
  background-color: #fff;
  padding: 16px 11px;
  box-sizing: border-box;
  line-height: 28px;
  text-align: center;
  border-bottom: 1px solid #e3e7eb;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
`;

export const Back = styled.button`
  width: 24px;
  height: 24px;
  background-color: transparent;
  display: inline-block;
  vertical-align: middle;
  float: left;
`;

export const Title = styled.span`
  display: inline-block;
  vertical-align: middle;
  clear: both;
  margin-left: -24px;
`;
