import styled from '@emotion/styled';

export const IntroBackground = styled.section`
  width: 100%;
  height: 100vh;
  background: linear-gradient(180deg, #3fc8c0 0%, #02a4d5 100%);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const Mention = styled.div`
  padding-top: 25px;
  padding-bottom: 20px;
  font-size: 20px;
  color:white;
`

export const TitleContainer = styled.div`
  height: 20%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 500px) {
    height: 35%;
  }
`

export const ButtonContainer = styled.div`
  width: 100%;
  height: 15%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  @media screen and (max-width: 500px) {
    height: 20%;
  }
`