import React, {FC} from "react";

interface Props {
    color: string;
}

const KakaoTalkIcon: FC<Props> = ({color}) => {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3225_19)">
                <path
                    d="M11.0615 9.05933C11.2639 9.05933 11.4351 9.10998 11.5753 9.21518C11.4313 9.10998 11.2639 9.05933 11.0615 9.05933C10.6645 9.07102 10.3959 9.26584 10.248 9.636C10.3959 9.26974 10.6645 9.07102 11.0615 9.06322V9.05933Z"
                    fill={color}/>
                <path
                    d="M11.6881 24.2127C11.8087 24.1192 12.0033 24.0647 12.1551 24.0802C13.7704 24.2595 15.3739 24.2088 16.9658 23.8932C20.3714 23.223 23.3139 21.7034 25.4896 18.9486C28.109 15.6288 28.7239 11.9311 27.1204 7.98784C25.8477 4.85898 23.4657 2.75488 20.4337 1.37164C17.6391 0.097495 14.6967 -0.241497 11.6647 0.155942C9.31776 0.463763 7.14206 1.23526 5.20767 2.61851C2.72449 4.3953 1.03531 6.72928 0.307478 9.71787C0.167362 10.2984 0.101195 10.8946 0 11.4869V13.0727C0.101195 13.6338 0.167362 14.2066 0.303586 14.7599C0.891298 17.1406 2.20295 19.0694 4.06339 20.6319C4.60439 21.0878 5.19989 21.4774 5.78371 21.8787C5.95107 21.9917 5.97831 22.0852 5.92772 22.2723C5.57353 23.5308 5.22324 24.7933 4.8963 26.0596C4.81846 26.3636 4.80289 26.6948 4.82624 27.0065C4.86127 27.5325 5.22713 27.8131 5.67862 28.0001H6.27801C7.06422 27.7195 7.74534 27.2559 8.40311 26.7493C9.4968 25.8999 10.5866 25.0505 11.6881 24.2127ZM6.78009 25.6583C7.11482 24.4699 7.45343 23.2815 7.78037 22.0891C7.83875 21.8826 7.87378 21.6605 7.86211 21.4462C7.84265 21.115 7.61301 20.9202 7.33278 20.7682C6.14568 20.1136 5.06366 19.3188 4.15291 18.3057C1.35446 15.2002 0.965249 10.7972 3.19155 7.24751C4.91187 4.50829 7.41451 2.87567 10.5204 2.14314C13.2955 1.49243 16.0512 1.60153 18.7445 2.56785C21.146 3.42897 23.1465 4.84339 24.6061 6.96696C26.9141 10.3257 26.7195 14.7209 24.1273 17.8654C22.2124 20.1916 19.7175 21.5319 16.8023 22.1398C15.1754 22.4788 13.5368 22.5061 11.8866 22.284C11.6764 22.2567 11.4117 22.3502 11.2288 22.471C10.5243 22.9386 9.83931 23.4373 9.15819 23.9361C8.37587 24.5127 7.60134 25.105 6.8268 25.6934C6.81123 25.6817 6.79566 25.6661 6.78009 25.6544V25.6583Z"
                    fill={color}
                />
                <path
                    d="M20.0173 16.5366C20.6361 16.8055 21.2472 16.4198 21.2628 15.7457C21.2744 15.2041 21.2628 14.6624 21.2628 14.0351C21.3834 14.1676 21.4496 14.2377 21.5119 14.3118C22.084 14.982 22.6445 15.6677 23.2361 16.3223C23.5941 16.7198 24.1157 16.7276 24.4777 16.3964C24.8357 16.0613 24.8591 15.5547 24.5244 15.1456C24.2363 14.7949 23.9328 14.452 23.637 14.1091C23.2244 13.6299 22.8118 13.1467 22.3837 12.6441C22.4771 12.5623 22.5549 12.4999 22.625 12.4298C23.2361 11.818 23.8471 11.2063 24.4582 10.5945C24.6723 10.3802 24.8241 10.1348 24.7696 9.81914C24.7073 9.45677 24.4971 9.21129 24.1546 9.08271C23.816 8.95802 23.528 9.05543 23.2828 9.30091C22.6951 9.88538 22.1074 10.4698 21.5274 11.0621C21.4613 11.1284 21.434 11.2375 21.3912 11.3271C21.3484 11.2959 21.3095 11.2647 21.2667 11.2336C21.2667 11.1634 21.2667 11.0972 21.2667 11.027C21.2667 10.6335 21.2744 10.2439 21.2589 9.85031C21.2433 9.42949 20.967 9.13726 20.5544 9.07491C19.9472 8.9814 19.523 9.35156 19.5191 9.99838C19.5113 10.9725 19.5191 11.9466 19.5191 12.9207C19.5191 13.8403 19.5191 14.7599 19.5191 15.6794C19.5191 16.0613 19.6397 16.3769 20.0212 16.5405L20.0173 16.5366Z"
                    fill={color}
                />
                <path
                    d="M14.2142 15.4417C13.9496 14.7443 13.6654 14.0546 13.3891 13.3649C12.8987 12.1414 12.4122 10.9179 11.9218 9.69445C11.8361 9.47625 11.7194 9.31649 11.5793 9.21518C11.4352 9.10998 11.2679 9.05933 11.0655 9.05933C10.6685 9.07102 10.3999 9.26584 10.252 9.63211C9.48139 11.557 8.71075 13.4779 7.95178 15.4067C7.88562 15.5742 7.87394 15.7885 7.91286 15.9639C7.99071 16.3379 8.25926 16.5522 8.62902 16.6224C8.99877 16.6964 9.28679 16.5366 9.45026 16.221C9.64097 15.8509 9.79276 15.4612 9.94845 15.0716C10.0068 14.9235 10.0847 14.8651 10.2481 14.869C10.8047 14.8806 11.3574 14.8806 11.914 14.869C12.0813 14.869 12.1514 14.9235 12.2059 15.0755C12.3343 15.4261 12.4744 15.7729 12.6223 16.1119C12.7819 16.4743 13.0738 16.6457 13.4436 16.6224C14.0663 16.5795 14.4439 16.0223 14.222 15.4378L14.2142 15.4417ZM10.7424 13.1117C10.8514 12.8389 10.9487 12.5895 11.0694 12.2739C11.19 12.5778 11.2912 12.8311 11.4041 13.1117H10.7424Z"
                    fill={color}
                />
                <path
                    d="M11.5752 9.2113C11.7192 9.31651 11.8321 9.47626 11.9177 9.69057C11.8321 9.47237 11.7153 9.31261 11.5752 9.2113Z"
                    fill={color}
                />
                <path
                    d="M8.43823 9.92428C8.44212 9.39825 8.09572 9.04757 7.53915 9.03978C6.39486 9.03199 5.24668 9.03199 4.1024 9.03978C3.54971 9.04368 3.2111 9.40215 3.21888 9.93207C3.22667 10.4503 3.56139 10.7737 4.11018 10.7854C4.37874 10.7893 4.65119 10.7854 4.95866 10.7854C4.95866 11.592 4.95866 12.344 4.95866 13.0999C4.95866 13.9844 4.95866 14.865 4.95866 15.7495C4.95866 16.1781 5.17273 16.4586 5.54638 16.5716C6.18079 16.7626 6.70234 16.3651 6.70234 15.6677C6.70623 14.1558 6.70234 12.644 6.70234 11.1322V10.7854C6.99814 10.7854 7.26281 10.7854 7.52358 10.7854C8.08794 10.7776 8.43045 10.4464 8.43434 9.92038L8.43823 9.92428Z"
                    fill={color}
                />
                <path
                    d="M15.81 16.6263C16.5378 16.6263 17.2657 16.6263 17.9935 16.6263C18.5695 16.6263 18.9276 16.2873 18.9237 15.7495C18.9237 15.2118 18.5695 14.8806 17.9896 14.8767C17.5381 14.8767 17.0827 14.8767 16.604 14.8767C16.604 13.2207 16.604 11.6076 16.604 9.99447C16.604 9.41389 16.2693 9.05932 15.7361 9.05542C15.199 9.05542 14.8564 9.41 14.8564 9.98278C14.8564 11.8804 14.8564 13.774 14.8564 15.6716C14.8564 16.2678 15.2145 16.6263 15.8061 16.6263H15.81Z"
                    fill={color}
                />
            </g>
            <defs>
                <clipPath id="clip0_3225_19">
                    <rect width="28" height="28" fill={color}/>
                </clipPath>
            </defs>
        </svg>
    )
}

export default KakaoTalkIcon;