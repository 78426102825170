import styled from '@emotion/styled';

export const Container = styled.section`
  margin: 0 auto;
  margin-top: 20px;
  max-width: 600px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export const ImageItem = styled.div`
  max-width: 290px;
  max-height: 290px;
  flex: 1 1 40%;
  margin-bottom: 5px;
  &:nth-of-type(odd) {
    margin-right: 2.5px;
  }
  &:nth-of-type(even) {
    margin-left: 2.5px;
  }

  & img {
    width: 100%;
    height: 100%;
  }
`;
