import React, { FC } from 'react';

interface Props {
  color?: string;
}

const YoutubeIcon: FC<Props> = ({ color }) => {
  return (
    <svg width="25" height="25" viewBox="0 0 24 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1.55078" y="0.750122" width="21.3" height="17.5" rx="4.25" stroke={color} strokeWidth="1.5" />
      <path
        d="M15.0762 9.24143L10.2387 12.0344C10.072 12.1306 9.86367 12.0103 9.86367 11.8179L9.86367 6.232C9.86367 6.03954 10.072 5.91926 10.2387 6.01549L15.0762 8.80842C15.2428 8.90465 15.2428 9.14521 15.0762 9.24143Z"
        stroke={color}
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default YoutubeIcon;
