import React from 'react';
import {ButtonContainer, IntroBackground, Mention, TitleContainer} from "./styles";
import {GooglePlayButton, AppStoreButton} from "react-mobile-app-button";
import urIcon from "../../assets/images/UrLogo.png";

const introComponent = () => {
    return (
        <IntroBackground>
            <TitleContainer>
                <img src={urIcon} alt="urIcon" className="urLogo"/>

                <Mention> Download UR App </Mention>
            </TitleContainer>
            <ButtonContainer>
            <GooglePlayButton
                url={"https://play.google.com/store/apps/details?id=com.icon.inocamx"}
                theme={"dark"}
                className={"custom-style"}
            />
            <AppStoreButton
                url={""}
                theme={"dark"}
                className={"custom-style"}
            />
        </ButtonContainer>
</IntroBackground>
)
}


export default introComponent;