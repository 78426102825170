import React, { FC } from 'react';

interface Props {
  color?: string;
}

const DiscordIcon: FC<Props> = ({ color }) => {
  return (
    <svg viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.5664 14.227L14.4014 15.822C14.6214 16.242 15.0814 16.477 15.5464 16.412C18.3064 16.017 19.9764 14.602 20.7014 13.832C20.9714 13.542 21.0664 13.132 20.9564 12.757L18.0514 2.89703C17.9414 2.52703 17.6514 2.24203 17.2814 2.13703L15.1564 1.54203C14.5914 1.38203 13.9964 1.69203 13.8014 2.24703L13.2314 3.86703"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M8.76987 3.8667L8.19986 2.2467C8.00486 1.6917 7.41486 1.3867 6.84486 1.5417L4.71986 2.1367C4.34986 2.2417 4.05987 2.5267 3.94987 2.8967L1.04487 12.7567C0.934865 13.1367 1.02987 13.5467 1.29987 13.8317C2.02487 14.6017 3.68987 16.0167 6.45487 16.4117C6.92487 16.4767 7.38487 16.2417 7.59987 15.8217L8.43487 14.2267"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M6.03125 4.47749C6.03125 4.47749 10.9212 2.87249 15.9712 4.47749"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M15.9712 13.707C15.9712 13.707 11.0812 15.312 6.03125 13.707"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M8.23113 11.3983C8.67321 11.3983 9.03159 11.0399 9.03159 10.5978C9.03159 10.1557 8.67321 9.79736 8.23113 9.79736C7.78904 9.79736 7.43066 10.1557 7.43066 10.5978C7.43066 11.0399 7.78904 11.3983 8.23113 11.3983Z"
        fill={color}
      />
      <path
        d="M13.8962 11.3983C14.3382 11.3983 14.6966 11.0399 14.6966 10.5978C14.6966 10.1557 14.3382 9.79736 13.8962 9.79736C13.4541 9.79736 13.0957 10.1557 13.0957 10.5978C13.0957 11.0399 13.4541 11.3983 13.8962 11.3983Z"
        fill={color}
      />
    </svg>
  );
};

export default DiscordIcon;
