import React, { FC, useCallback } from 'react';

import { LinkContainer, LinkName, LinkThumbnail, ComingSoonLabel, ItemWrapper } from './styles';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga';

interface Props {
  thumbnail?: string | undefined;
  name: string;
  url?: any;
  path?: string | undefined;
  themeName?: string;
  fontName?: any;
  youtubeView?: boolean;
  setIsYoutube?: any;
  nickName: string;
  label?: string;
}

const LinkItem: FC<Props> = ({
  thumbnail,
  name,
  url,
  path,
  themeName,
  label,
  fontName,
  youtubeView,
  setIsYoutube,
  nickName,
}) => {
  const navigate = useNavigate();

  const handleLink = useCallback(
    (link: string, name: string) => {
      window.location.href = `${link}`;
      ReactGA.event({ category: nickName, action: `${nickName} ${name}`, label: 'Link Button' });
      // window.open(`${link}`, '_blank');
      if (window.android) {
        window.android.getTitle(`${name}`);
      }
    },
    [nickName],
  );

  const handlePath = useCallback(
    (path: string, name: string) => {
      navigate(path);
      ReactGA.event({ category: nickName, action: nickName + name, label: 'Link Button' });
      if (window.android) {
        window.android.getTitle(`${name}`);
      }
    },
    [navigate, nickName],
  );

  const handleYoutube = useCallback(
    (name: string) => {
      setIsYoutube(true);
      ReactGA.event({ category: nickName, action: nickName + name, label: 'Link Button' });
    },

    [setIsYoutube, nickName],
  );

  return (
    <>
      <ItemWrapper hasUrl={!!url} hasPath={!!path} hasYoutubeView={!!youtubeView}>
        {/* 컨테이너를 추가하여 라벨의 절대 위치 기준점 제공 */}
        {!url && !path && !youtubeView && <ComingSoonLabel>{label}</ComingSoonLabel>}
        <LinkContainer
          className={`${thumbnail ? 'isThumbnail' : ''} ${themeName === 'sphh' ? 'sphh' : ''} ${
            themeName === 'dongwon' || themeName === 'kiff' || themeName === 'mhsc' ? 'dongwon' : ''
          }`}
          disabled={!url && !path && !youtubeView}
          onClick={(e) => {
            if (!url && !path && !youtubeView) {
              e.preventDefault();
              return;
            }
            path ? handlePath(path, name) : youtubeView ? handleYoutube(name) : handleLink(url, name);
          }}
        >
          {thumbnail && (
            <LinkThumbnail
              src={thumbnail}
              alt="thumbnail"
              className={`${themeName === 'sphh' ? 'sphh' : ''} ${
                themeName === 'dongwon' || themeName === 'kiff' || themeName === 'mhsc' ? 'dongwon' : ''
              }`}
            />
          )}
          <LinkName
            className={`${themeName === 'sphh' ? 'sphh' : ''} ${
              themeName === 'dongwon' || themeName === 'kiff' || themeName === 'mhsc' ? 'dongwon' : ''
            }`}
            fontName={fontName}
            disabled={!url && !path && !youtubeView}
          >
            {name}
          </LinkName>
        </LinkContainer>
      </ItemWrapper>
    </>
  );
};

export default LinkItem;
