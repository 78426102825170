import React, { FC } from 'react';

interface Props {
  color?: string;
}

const PlayStoreIcon: FC<Props> = ({ color }) => {
  return (
    <svg viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_74_380)">
        <path d="M0.00195312 17.7988L10.3011 6.99883" stroke={color} strokeWidth="1.5" strokeLinejoin="round" />
        <path d="M11.2988 12.7993L0.999723 1.99932" stroke={color} strokeWidth="1.5" strokeLinejoin="round" />
        <path
          d="M16.125 9.7835C16.2917 9.87972 16.2917 10.1203 16.125 10.2165L1.125 18.8768C0.958335 18.973 0.749999 18.8527 0.749999 18.6603L0.75 1.33975C0.75 1.1473 0.958336 1.02702 1.125 1.12324L16.125 9.7835Z"
          stroke={color}
          strokeWidth="1.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_74_380">
          <rect width="17" height="20" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PlayStoreIcon;
