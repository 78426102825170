import React, { useCallback } from 'react';

import { sphhLinkList } from 'utils/dummy';

import { Container, ImageItem } from './styles';
import { useNavigate } from 'react-router-dom';

const FeedComponent = () => {
  const navigate = useNavigate();

  const handleFeedItem = useCallback(
    (index: number) => {
      navigate('/sphh/feed/view', {
        state: {
          indexNumber: index,
        },
      });
    },
    [navigate],
  );

  return (
    <Container>
      {sphhLinkList.feedList.map((feed, index: number) => (
        <ImageItem key={index} onClick={() => handleFeedItem(index)}>
          <img src={feed.image} alt={feed.name} />
        </ImageItem>
      ))}
    </Container>
  );
};
export default FeedComponent;
