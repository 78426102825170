import React from 'react';
import { Back, Container, Title } from './styles';

import backIcon from 'assets/icons/ArrowLeft.svg';
import { useNavigate } from 'react-router-dom';

const HeaderNavigation = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <Back onClick={() => navigate(-1)}>
        <img src={backIcon} alt="backIcon" width="100%" height="100%" />
      </Back>
      <Title>어텐션미 콘테스트</Title>
    </Container>
  );
};

export default HeaderNavigation;
