import React, { FC } from 'react';

interface Props {
  color?: string;
}

const TwitterIcon: FC<Props> = ({ color }) => {
  return (
    <svg viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.996 3.63C20.996 3.63 19.556 4.56 18.661 4.29C18.631 4.28 18.601 4.27 18.571 4.255C18.571 4.255 18.596 4.24 18.636 4.215C18.921 4.03 20.061 3.245 20.511 2C20.511 2 18.481 3.03 17.956 3.005C17.206 2.085 16.061 1.5 14.781 1.5C12.521 1.5 10.686 3.335 10.686 5.595C10.686 5.995 10.741 6.38 10.851 6.74C9.73098 6.845 8.61098 6.675 7.47098 6.27C5.55598 5.585 3.86598 4.34 2.49598 2.855C2.38598 2.735 2.27598 2.615 2.17098 2.49C2.17098 2.49 0.485976 5.94 3.31098 7.94C3.31098 7.94 1.82598 7.71 1.51598 7.425C1.51598 7.425 1.54598 11.25 4.99598 11.535C4.99598 11.535 3.37098 11.82 2.94098 11.65C2.94098 11.65 4.51098 14.99 6.90598 14.56C6.90598 14.56 4.45098 16.645 1.00098 16.33C1.10098 16.34 1.20098 16.425 1.27598 16.48C1.64098 16.735 2.09098 16.91 2.50098 17.08C3.75098 17.595 5.08098 17.925 6.42598 18.035C10.006 18.32 13.491 16.93 15.881 14.26C17.931 11.97 19.331 9.015 19.146 5.89C19.146 5.89 20.516 4.69 21.001 3.635L20.996 3.63Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TwitterIcon;
