import React from 'react';
import ConfirmPageComponent from 'components/ConfirmPageComponent';
import { useLocation } from 'react-router-dom';

const ConfirmPage = () => {
  let location = useLocation();
  return (
    <>
      {location.pathname === '/indonesia' && <ConfirmPageComponent indonesia={true} />}
      {location.pathname === '/ssg_gift' && <ConfirmPageComponent />}
      {location.pathname === '/incellderm' && <ConfirmPageComponent incellderm={true} />}
    </>
  );
};

export default ConfirmPage;
