import styled from '@emotion/styled';

export const Container = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  //height: 100vh;
  background: #000;
  position: relative;
`;

export const ContentBox = styled.div`
  padding-top: 56px;
  box-sizing: border-box;
`;
